











































































































































import { Component, Mixins, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import ValidatorMixin from '@/modules/shared/mixins/ValidatorMixin'

import UserModel from '@/modules/shared/entities/form-models/User'

import DeleteIcon from '@/modules/shared/components/icons/DeleteIcon.vue'
import PrimaryButton from '@/modules/shared/components/PrimaryButton.vue'

const AuthModule = namespace('auth')
const AdminUserModule = namespace('admin_user')

@Component({
  components: {
    DeleteIcon,
    PrimaryButton,
  },
})
export default class UserForm extends Mixins(ValidatorMixin) {
  @AdminUserModule.State
  private readonly species!: unknown[]

  @AdminUserModule.Getter
  private readonly formModel!: UserModel|null

  @AdminUserModule.Mutation
  private readonly setFormModel!: (formModel: UserModel|null) => Promise<void>

  @AdminUserModule.Action
  private readonly saveUser!: (formModel: UserModel|null) => Promise<void>

  @AuthModule.Getter
  private readonly isAdmin!: boolean

  @Watch('formModel')
  updateModel(newValue: UserModel|null = null) {
    this.model = (newValue === null) ? newValue : newValue?.clone()
  }

  @Watch('model.password')
  changePassword(val: string) {
    if (val === '') this.resetValidation()
  }

  private model: UserModel|null = null

  private rePassword = ''

  private get isOpen(): boolean {
    return this.model !== null
  }

  private set isOpen(open: boolean) {
    if (open === false) {
      this.reset()
      this.setFormModel(null)
    }
  }

  private get isEditing(): boolean {
    return !!this.model?.id
  }

  private confirmed(val: string) {
    return (this.model?.password === val) || this.$i18n.t('admin.user.form.fields.re_password.error.not_match')
  }

  private async submit() {
    if (!this.validate()) return

    try {
      await this.saveUser(this.model)

      this.reset()
    } catch (err) {
      console.error(err)
    }
  }
}
