









































import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { debounce } from '@/modules/shared/utils/functions'

import UserFilterEntity from '@/modules/shared/entities/UserFilter'
import UserModel from '@/modules/shared/entities/form-models/User'

import DeleteIcon from '@/modules/shared/components/icons/DeleteIcon.vue'
import SearchIcon from '@/modules/shared/components/icons/SearchIcon.vue'
import PrimaryButton from '@/modules/shared/components/PrimaryButton.vue'
import Icon from '@/modules/shared/components/Icon.vue'

const AdminUserModule = namespace('admin_user')

@Component({
  components: {
    DeleteIcon,
    SearchIcon,
    PrimaryButton,
    Icon,
  },
})
export default class UserFilter extends Vue {
  @AdminUserModule.Getter
  private readonly filter!: UserFilterEntity

  @AdminUserModule.Action
  private readonly fetchUsers!: (filter: UserFilterEntity) => Promise<void>

  @AdminUserModule.Mutation
  private readonly setFormModel!: (formModel: UserModel|null) => Promise<void>

  private debouncedApplyFilter = debounce(this.applyFilter, 750)

  private applyFilter(name: string, value: string|number|number[]): void {
    const newFilter = this.filter.copyWith({
      [name]: value,
      page: 1,
    })

    this.fetchUsers(newFilter)
  }

  private clearSearch() {
    const newFilter = UserFilterEntity.empty()
    this.fetchUsers(newFilter)
  }

  private addUser() {
    this.setFormModel(UserModel.empty())
  }
}
